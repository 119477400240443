export default defineNuxtRouteMiddleware(async (_to, _from) => {
  const consola = useConsola()

  consola.debug('subdomain-box middleware')
  consola.debug(window.location)
  consola.debug(location)

  const subdomain = getSubdomain()
  if (subdomain !== 'box') {
    return createError({ statusCode: 404, message: 'ページが見つかりません' })
  }
})
